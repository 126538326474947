import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDPgrRYVSlJXZ9udTnI8PCy-qIGaOIgCVQ",
  authDomain: "spaety-4hd-beefreund.firebaseapp.com",
  databaseURL: "https://spaety-4hd-beefreund-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "spaety-4hd-beefreund",
  storageBucket: "spaety-4hd-beefreund.appspot.com",
  messagingSenderId: "203572716862",
  appId: "1:203572716862:web:99e84f8737a1ecdb75b86f",
};

const fb = firebase.initializeApp(firebaseConfig)
const veri = fb.database()
const fonksiyon = fb.functions()
const depo = fb.storage()
const giris = fb.auth()

fb.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = giris.onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

export {
  fb,
  veri,
  fonksiyon,
  depo,
  giris,
}
